import React, { useEffect, useState } from 'react';
import { MfePropsType } from '../src/types/mfeProps';
import * as skeletonHtmls from '../src/skeleton';

const RootComponent = import('./root.component');

const RootLoader = (props: MfePropsType) => {
  const [Component, setComponent] = useState<any>(React.Fragment);

  const microfrontendRouter = (window as any).Shell?.v1?.microfrontendRouter;
  const currentRoute = (microfrontendRouter as any)?.getState()?.content;

  const skeletonFileName = (): keyof typeof skeletonHtmls => {
    const skeletonReference = currentRoute?.skeletonReference || 'index';
    return skeletonReference.endsWith('.html')
      ? skeletonReference.slice(0, -5)
      : skeletonReference;
  };

  const skeletonHtml = skeletonHtmls?.[skeletonFileName()];

  useEffect(() => {
    RootComponent.then((response) =>
      setComponent(() => response?.default as any)
    );
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        maxWidth: '100%',
        maxHeight: '100%',
        zIndex: 0
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: -1
        }}
        dangerouslySetInnerHTML={{ __html: skeletonHtml }}
      />
      <Component {...props} />
    </div>
  );
};

export default RootLoader;
